import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Block from '~/src/components/Block';
import Figure from '~/src/components/Figure';
import SEO from '~/src/components/SEO';
import clients from '~/static/clients.png';
import styled from 'styled-components';
import Layout from '~/src/components/Layout';


const DescriptionText = styled.li`
      font-family: 'NB';
      font-size:16px;
    
`;

const AboutSection = ({ posts, transition, data }) => (
  <Layout>
    <Helmet title="Raul Perte | About" />
    <SEO
      postPath="/about"
      postNode={{
        subtitle: 'A few things about me',
        excerpt: `Hello! I’m a designer & developer focused on turning ideas into delightful, practical software. I’m doing my best work when collaborating closely with founders, designers, and developers.`,
        cover: data.cover,
      }}
      pageSEO
    />
    <Header cover={data.cover}>
      <div className="title">
        <h1>A few things about me</h1>
      </div>
      <div className="intro">
        <ul>
          <DescriptionText>'nuff said</DescriptionText>
          <li>Interdisciplinary designer.</li>
          <li>Makes iOS apps.</li>
          <li>Runs a successful agency.</li>
        </ul>
      </div>
    </Header>
    <article id="content">
      <div>
        <Block pull mobilePull>
          <Figure
            src={clients}
            captionLeft
            caption="Some of the companies I’ve worked with."
          />
        </Block>
        <Block align="right" pull mobilePull>
          <p>
           👋! I'm currently living in Cluj-Napoca and was until not so long ago consulting for Deutsche Bank in Berlin. I joined their talented team to help rethink how people save money with every single purchase they make.
           In my free time, I design and code for <a href="//weatherkit.org">WeatherKit</a>, a simple weather app that was featured by Apple for its design and is currently the no. 1 weather app in over 25 countries.
          </p>
          <p>
            Recently, I led design at{' '}
            <a href="/work/stoovo">Stoovo</a> in a friendly and remote environment,
            helping tens of thousands of students cut down their monthly costs and matching them with gigs. 
            Many of the projects I've been involved with try hard to improve how we live today and I couldn't be more proud putting some effort towards that direction.
             I'm a firm believer that successful design teams prototype, test, iterate and work together using design for good to help and enrich the lives of others.
          </p>
          <p>
            I've been freelancing since '13 and had the luck of working with incredibly talented people, at big and small companies. Some of the highlights are <a href="//motionfigures.com">MotionFigures</a>,{' '}
            <a href="//voya.ai">Voya.ai</a>, <a href="//duckduckgo.com">DuckDuckGo</a>, and{' '}
            <a href="//soundcloud.com">SoundCloud</a>. I <a href="https://twitter.com/perte">tweet</a> {' '} about design and software, ocassionally <a href="/articles">write</a> {' '} on my blog and enjoy meeting new people.  
          </p>
          <p>
            If you have a project in mind, feel free to reach out. 
          </p>
          <p>
        
            <a href="http://bit.ly/perteresume">
              Résumé
            </a>{' '}·
            <a href="https://www.linkedin.com/in/perte"> LinkedIn</a>
          </p>
        </Block>
      </div>
    </article>
  </Layout>
);

export default AboutSection;

export const query = graphql`
  query GatsbyImageHeroAboutQuery {
    cover: file(relativePath: { eq: "hero-bw.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
